import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { StyledButton } from '../../utils/style/Atoms'
import colors from '../../utils/style/colors'
import { useTheme } from '../../utils/hooks'

const StyledText = styled.p`
    color: ${({ theme }) => (theme === 'light' ? colors.black : colors.black)};
    transition: all 200ms;
    padding: 0 30px;
`


const TestContainer = styled.section`
    margin: auto;
    text-align: center;
    box-shadow: 2px -2px 6px ${({ theme }) => (theme === 'light' ? colors.shadow : colors.shadow )};
    padding: 15px 0 25px 0;
    ${StyledButton} {
        padding: 5px 20px;
        max-width: 180px;
        font-size: 16px;
        transition: all 200ms;
        margin-top: 10px;
    }
`

function Test() {

    const { theme } = useTheme()
    
    return (

        <TestContainer theme={theme}>
            <StyledText theme={theme}>
                Besoin d'aide pour définir votre projet ? <br/>
                Ce test rapide est fait pour vous !
            </StyledText>
            <Link to="/votre-projet/1" title="Quel est votre projet ?">
                <StyledButton to="/votre-projet/1" theme={theme}>
                    Je passe le test
                </StyledButton>
            </Link>
        </TestContainer>
    )
}
export default Test