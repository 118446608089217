import styled from 'styled-components'
import React, { useEffect } from 'react'
import colors from '../../utils/style/colors'
import { useTheme, usePageTitleContext } from '../../utils/hooks'
import Test from '../../components/TestContainer'
import { Helmet, HelmetProvider } from 'react-helmet-async';



const PageWrapper = styled.main`
    margin: auto;
    max-width: 2400px;
    @media screen and (min-width: 2399px) {
        box-shadow: rgb(226, 227, 233) 2px -2px 6px;
    }
`

const MainSection = styled.section`
    margin: auto;
    padding: 20px 20px;
    align-items: center;
    background-color: ${({ theme }) => (theme === 'light' ? `${colors.backgroundLight}` : `${colors.backgroundLight}`)};
    transition: all 200ms;
    z-index: -1;
    max-width: 1400px;
`

const Rules = styled.p`
    text-align: justify;
    &>li {
        margin-left: 2rem;
    }
`



function CGV() {
    const { theme } = useTheme()
    const { title, setTitle } = usePageTitleContext()
     
    
    // Set header page
    useEffect(() => {
        setTitle('Conditions Générales de Vente');
    }, [title, setTitle]);

    // Set meta tags when Home Page loads
    const usePageMeta = (title, description) =>{
        const defaultTitle = "Conditions Générales de Vente";
        const defaultDesc = "Voici les conditions générales de vente de Becom'in, entreprise experte en programmation et développement informatique près de Caen, en Normandie";
    
        useEffect(() => {
            // Title meta tag
            document.title = title || defaultTitle;
            // Description meta tag
            document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);
        }, [defaultTitle, title, defaultDesc, description]);
    };
    
    usePageMeta("Conditions Générales de Vente", "Voici les conditions générales de vente de Becom'in, entreprise experte en programmation et développement informatique près de Caen, en Normandie");
    

    return (
        <HelmetProvider>
            <PageWrapper id='main-content'>
                <Helmet>
                    <link rel="canonical" href="https://www.becom-in.fr/conditions-generales-de-vente" />
                </Helmet>
                <MainSection theme={theme}>
                    <Rules>

                        <b><u>Article 1 - Application</u></b><br />
                        <br />
                        Les conditions générales de vente décrites ci-après reprennent les droits et obligations applicables à toutes les commandes de prestations de services passées à la société BECOM'IN, 
                        nom commercial appartenant à l'entreprise OFFICE PRO SECRETARIAT, 
                        SARL dont le siège social se situe au 7, avenue de Glattbach - 14760 BRETTEVILLE-SUR-ODON, immatriculée au Registre des Métiers sous le numéro SIRET 882 500 630 000 18.<br />
                        <br />
                        Les prestations accomplies par BECOM'IN pour le compte d’un Client impliquent que le Client ait pris connaissance des présentes conditions et qu’il y adhère dans les mêmes conditions.<br />
                        <br />
                        Le Client renonce ainsi à toute application de ses éventuelles conditions générales d’achat.<br />

                        <br /><br />
                        <b><u>Article 2 - Prestations</u></b><br />
                        <br />
                        BECOM'IN propose des prestations ponctuelles ou régulières de création ou modification de sites internet et d'application mobiles.<br />
                        <br />
                        Ces prestations dédiées aux professionnels incluent les dimensions et spécialités suivantes :<br />
                        <li>Création / modification de site vitrine, e-commerce, forum, application collaborative.</li>
                        <li>Création / maintenance d'applications mobiles Android.</li>
                        <li>Audit et optimisation SEO.</li>
                        <li>Développement de projets sur-mesure.</li>

                        <br /><br />
                        <b><u>Article 3 - Lieux</u></b><br />
                        <br />
                        Les prestations prévues à l’article 2 sont réalisées depuis les bureaux de l'entreprise BECOM'IN, sur la France entière. Néanmoins, pour des raisons préalablement convenues, elles pourront être réalisées dans les bureaux du client, et ce, dans un rayon de 20 kilomètres du siège de l’entreprise BECOM'IN, et dans un rayon plus étendu s’il y a accord des deux parties sur les modalités.
                        <br />
                        <br /><br />
                        <b><u>Article 4 - Devis et Commande</u></b><br />
                        <br />
                        Toute demande de prestation donne lieu à l’établissement d’un devis personnalisé, gratuit et sans engagement, dans les 72 heures suivant la réception de la demande. Il y sera mentionné de manière explicite les tâches à accomplir, leur tarif en Euros HT et TTC, ainsi que les délais d'exécution des travaux, les éventuels frais annexes (frais de déplacement, frais d'expédition...) et remises, le cas échéant.
                        <br /><br />
                        Sera considérée comme une commande ferme et définitive, tout devis daté, signé et portant la mention « Bon pour accord », dans un délai d'un mois maximum. Passé le délai d'un mois, l'offre devient caduque. Le devis signé devra obligatoirement être accompagné d’un acompte de 30% du montant total des prestations. Le solde sera payé à réception de la facture.
                        <br /><br />
                        Toute commande se voit enregistrée à compter de la date à laquelle le règlement de l’acompte et le devis signé sont parvenus au siège social de l'entreprise BECOM'IN, mentionné à l'Article 1.
                        <br /><br />
                        Toute modification de la commande fera l'objet d'un avenant chiffré qui devra être accepté par les deux parties. En cas de désaccord, la commande initiale prévaudra.
                        <br />
                        Les contrats et devis échangés par e-mail sont considérés comme équivalents aux documents originaux et font preuve de la commande et/ou de l'exécution des Prestations par BECOM'IN.
                        <br />
                        <br /><br />
                        <b><u>Article 5 - Collaboration</u></b><br />
                        <br />
                        Le Client s’engage à fournir à BECOM'IN tous les éléments nécessaires à la réalisation de la mission. Toute omission de la part du Client pourra entraîner une révision de la date de livraison des prestations, voire une annulation de la commande.
                        <br /><br />
                        Le Client peut transmettre ses données par les moyens suivants :<br />
                            <li>Par courriel : <u>contact@becom-in.fr</u></li>
                            <li>Par voie postale à l’adresse mentionnée à l’Article 1</li>

                        <br /><br />
                        <b><u>Article 6 – Engagement, informations et confidentialité</u></b><br />
                        <br />
                        La société BECOM'IN est assujettie à une obligation légale de secret professionnel.<br />
                        <br />
                        Chacune des parties s'engage à ne pas divulguer les informations confidentielles reçues de l'autre partie.<br />
                        Les informations confidentielles s'entendent des informations de toute nature, visuelles ou orales, sur quelque support que ce soit, relatives à la structure, l'organisation, les affaires, les projets et, le cas échéant, le personnel de chacune des parties.
                        <br /><br />
                        Ont également un caractère confidentiel, le contenu des Prestations ainsi que les rapports, courriers, informations, notes, devis, fournis par BECOM'IN au cours de l'exécution des Prestations. Ces documents sont communiqués au Client pour un usage strictement privé ou interne à l'entreprise Cliente et à la condition de ne pas les divulguer à des tiers ni de les annexer à un document que le Client serait amené à produire.
                        <br /><br />
                        Si le Client souhaite que tout ou partie de ces documents soient divulgués ou utilisés par un tiers, il doit en demander l'autorisation préalable par écrit à BECOM'IN. Des modalités applicables à cette divulgation seront alors fixées.
                        <br /><br />
                        Les obligations et restrictions énoncées ci-dessus ne s'appliquent pas aux informations confidentielles qui :<br />
                            <li>appartiennent au domaine public ou ont été acquises librement avant le début de la Prestation.</li>
                            <li>sont ou deviennent connues autrement qu'à la suite d'une violation du présent article.</li>
                            <li>sont ou deviennent connues grâce à d'autres sources non tenues à une restriction de divulgation.</li>
                            <li>doivent être communiquées en vertu d'une obligation légale ou professionnelle ou à la demande de toute autorité judiciaire ou réglementaire habilitée à exiger la divulgation des informations confidentielles.</li>
                        <br />
                        Le Client reconnaît et accepte, dans le cadre de son contrat :<br />
                            <li>que les parties puissent, sauf demande expresse contraire de l'autre partie, correspondre ou se transférer mutuellement des documents par courrier électronique circulant sur le réseau Internet.</li>
                            <li>qu'aucune des parties n'exerce de maîtrise sur la capacité, la fiabilité, l'accès ou la sécurité de ces courriers électroniques.</li>
                            <li>que la société BECOM'IN ne pourrait être tenue pour responsable de toute perte, dommage, frais ou préjudice occasionnés par la perte, le retard, l'interception, le détournement ou l'altération de tout courrier électronique causés par un fait quelconque.</li>
                            <br />
                        Sous réserve de ses obligations en matière de confidentialité, BECOM'IN se réserve le droit d'exécuter des Prestations pour des entreprises concurrentes de celle du Client.<br />
                        De façon générale, les parties s'engagent à respecter la réglementation applicable à la protection des données personnelles et notamment les dispositions de la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.<br />
                        L'entreprise BECOM'IN engage son savoir-faire et son professionnalisme à la bonne réalisation des missions qui lui sont confiées, ainsi qu’à respecter les délais convenus avec le Client lors de la prise de commande.<br />
                        <br />
                        <br />
                        <b><u>Article 7 - Délai de rétractation</u></b><br />
                        <br />
                        Les suspensions ou annulations de contrat par le Client devront être signifiées par lettre recommandée avec accusé de réception, au plus tard 14 jours francs après signature du devis, uniquement s’il est prévu un début d’exécution de la prestation postérieur à un délai de 14 jours. Passé ce délai, le contrat annulé sera facturé, même s’il n’a pas été exécuté. Par conséquent, l’acompte ne sera pas restitué au Client.
                        Le droit de rétractation du Client peut également être signifié par courriel, dans les mêmes délais. Toutefois, le droit de rétractation exercé numériquement est considéré comme acté uniquement lorsque le Client reçoit un courriel de confirmation, provenant de la société BECOM'IN, et accusant réception de la demande de rétractation du Client.
                        Dans le cadre d'une prestation dont le début d'exécution est inférieur à 14 jours suivant la signature du devis, le Client renonce explicitement à son droit de rétractation, comme mentionné dans l'annexe à l'article R. 121-2 du Code de la consommation, lié au droit de rétractation.
                        <br />
                        <br /><br />
                        <b><u> Article 8 - Tarifs et majorations</u></b><br />
                        <br />
                        Les tarifs des prestations sont ceux en vigueur au moment de la prise de commande par le Client.<br />
                        Toute prestation commandée par un Client et exécutée par BECOM'IN pour le compte d’une tierce personne ou entité est due par ce même Client.<br />
                        <br />
                        Les tarifs sont libellés en euros et soumis à une TVA de 20% (vingt pour cent), pour prestation de service, conformément au Code Général des Impôts. Les tarifs sont révisables au 1er janvier de chaque année. Toutefois, BECOM'IN se réserve le droit de modifier ses tarifs à tout moment de l'année, sans avis préalable.
                        <br /><br />
                        Pour des prestations urgentes ou dans certains cas, des majorations pourront être appliquées, comme pour exemple :<br />
                            <li>Prestations commandées en urgence ou après 17h30 : 30%.</li>
                            <li>Prestations demandées pour les week-end ou jours fériés : 50%.</li>
                            <li>Déplacement sur site : {'>'} 20 kilomètres : suivant le barème en vigueur.</li>
                            <br />
                        Des frais supplémentaires pourront également s’ajouter au devis accepté par le client :<br />
                        <li>Frais d’affranchissement suivant le tarif en vigueur.</li>
                        <li>Fournitures spécifiques à l’accomplissement des prestations sollicitées (Clé USB ,CD, périphérique de stockage ou autres fournitures de papeterie).</li>
                        <li>Frais de livraison (frais de déplacement suivant le barème en vigueur).</li>

                        <br /><br />
                        <b><u>Article 9 - Modalités de paiement</u></b><br />
                        <br />
                        Les prix sont payables à BECOM'IN à réception de la facture :<br />
                            <li>par chèque bancaire ou postal à l’ordre de la société "SARL OFFICE PRO SECRETARIAT"</li>
                            <li>par virement bancaire</li>


                        <br /><br />
                        <b><u>Article 10 - Escompte</u></b><br />
                        <br />
                        Aucun escompte ne sera consenti en cas de paiement anticipé.<br />
                        
                        <br /><br />
                        <b><u>Article 11 - Retard de paiement</u></b><br />
                        <br />
                        En cas de retard de paiement d'une demande d'acompte, BECOM'IN se réserve le droit d'interrompre toute prestation en cours jusqu'au règlement total des sommes dues.
                        <br />
                        Conformément à l’article 441-6 du Code de Commerce, tout défaut de paiement à la date de règlement figurant sur les factures des prestations fournies par BECOM'IN, des pénalités de retard seront appliquées au taux de 15% par mois, applicables dès le premier jour de retard, et ce, au prorata temporis, sur le nombre de jours de retard.
                        <br />
                        Conformément à la loi 2012-387 du 22 mars 2012, le décret n° 2012-1115 du 2 octobre 2012, articles L.441-6 et D.441-4 du Code de commerce, tout retard de paiement entraîne l’exigibilité d’une indemnité forfaitaire pour frais de recouvrement de 40 euros, dès le premier jour de retard.
                        <br />
                        En outre, si les frais de recouvrement s'avèrent supérieurs au montant forfaitaire de 40 euros, BECOM'IN se réserve le droit de réclamer une indemnité complémentaire, sur justifications.
                        <br />
                        <br /><br />
                        <b><u>Article 12 - Responsabilité et obligations du Client</u></b><br />
                        <br />
                        Afin de faciliter la bonne exécution des Prestations, le Client s'engage :<br />
                        <li>à fournir à BECOM'IN des informations et documents complets, exacts et dans les délais nécessaires sans que la société BECOM'IN soit tenue d'en vérifier le caractère complet ou l'exactitude. Tout document fourni par le Client et ne pouvant être traité par BECOM'IN peut donner lieu à une annulation du contrat sans contrepartie.</li>
                        <li>à prendre les décisions dans les délais et d'obtenir, le cas échéant, les approbations hiérarchiques nécessaires.</li>
                        <li>à être disponible tout au long de l'exécution des Prestations en fournissant des coordonnées téléphoniques et/ou adresse de courrier électronique valides afin d'être joignable.</li>
                        <li>à avertir directement BECOM'IN de toute difficulté éventuelle relative à l'exécution des Prestations.</li>
                        <br />
                        Par ailleurs, le Client s'engage à régler les Prestations selon les termes du contrat ou du devis.<br />
                        
                        <br /><br />
                        <b><u>Article 13 - Modalités de réalisation et livraison des prestations</u></b><br />
                        <br />
                        <u>Début des travaux</u><br />
                        La réalisation des prestations débute dès signature du devis, induisant acceptation des présentes CGV, et dès lors que l’acompte de 30% a été versé.
                        <br /><br />
                        <u>Délais</u><br />
                        Les délais de livraison sont donnés à titre indicatif. BECOM'IN s’engage à respecter et à restituer les prestations suivant les modalités convenues lors de la signature du devis avec le client (remise en main propre, mail, courrier postal (frais d’expédition à la charge du Client), coursier (frais d’expédition à la charge du Client).
                        <br /><br />
                        Le dépassement des délais ne peut en aucun cas donner lieu à l’annulation de la commande ou à bénéficier de dommages et intérêts au profit du contractant. Tout dépassement de délai dû à des modifications demandées par le Client en dehors de la prestation prévue sur le devis entraînera une facturation supplémentaire à la charge du Client.
                        <br /><br />
                        Il est précisé que les délais de livraison indiqués pour l’envoi postal, lors de la prise de la commande avec le Client, ne sont donnés qu’à titre indicatif et ne sont aucunement garantis, dans la mesure où ils ne dépendent pas de la société BECOM'IN, mais du transporteur choisi par le Client.
                        <br /><br />
                        Le choix du transport par voie postale et les risques induits sont supportés en totalité par le Client, y compris en cas de colis manquant ou détérioré lors de l’acheminement postal.
                        <br />
                        <br /><br />
                        <b><u>Article 14 - Responsabilités et force majeure</u></b><br />
                        <br />
                        Le Client dispose d’un délai de 24 heures après la restitution du travail pour formuler une demande de modifications ou de corrections. Une majoration tarifaire sera appliquée pour toute modification ou correction supérieure ou égale à 30 minutes.
                        <br />
                        Passé le délai de 24 heures, BECOM'IN n’assume plus aucune responsabilité. Tout dommage consécutif à la réalisation des prestations ne pourra entraîner la responsabilité civile de l'entreprise, de sa gérance, ou de ses salariés, conformément au droit commun.
                        <br /><br />
                        BECOM'IN sera responsable de la bonne exécution de ses prestations, sauf en cas de force majeure ou de cas fortuit.
                        <br /><br />
                        Force majeure : En application de l’article 1218 du Code Civil, BECOM'IN n’est pas responsable notamment en cas d’accident, d’incendie, d’inondation, d’interruption de la fourniture d’énergie, de matières premières ou de matériels, ainsi qu’en cas de grèves totales ou partielles de toute nature entravant la bonne marche des activités de l’entreprise, telles que les grèves de transports, des services postaux, des fournisseurs en énergie, des télécommunications, etc…
                        <br />
                        La survenance d’un cas de force majeure a pour effet de suspendre l’exécution par BECOM'IN des obligations contractuelles du devis, de la commande ou des présentes conditions générales de vente.
                        <br />
                        <br /><br />
                        <b><u>Article 15 - Propriété intellectuelle</u></b><br />
                        <br />
                        Sauf convention contraire dans les conditions particulières, tout document écrit en vue d’être utilisé par le Client restera la propriété de l'entreprise BECOM'IN jusqu’au paiement de la totalité du montant des factures relatives à la prestation de services.
                        <br /><br />
                        Pour les besoins propres des Prestations, BECOM'IN pourra utiliser des logiciels ou mettre en place des tableaux de bord, y compris sous forme de feuilles de calculs, de documents, de bases de données ou d'autres outils bureautiques et informatiques.
                        <br /><br />
                        Dans certains cas, ces aides peuvent être mises à la disposition du Client et sur sa demande.
                        <br /><br />
                        Dans la mesure où ces outils ont été développés spécifiquement pour les besoins de la société BECOM'IN et sans considération des besoins propres du Client, ceux-ci peuvent, après accord, être mis à la disposition du Client pendant la durée du contrat en l'état et sans aucune garantie attachée, à simple destination d'usage.
                        <br /><br />
                        Le Client s'interdit de partager, distribuer, commercialiser, mettre à disposition et plus généralement de concéder l'utilisation, en tout ou partie, de ces réalisations à des tiers sans l'accord de la société BECOM'IN.
                        <br /><br />
                        Aucune partie ne pourra faire mention ou usage du nom, de la dénomination, des marques et logos ou autres appellations, commerciales ou non, de l'autre partie sans accord préalable et écrit de cette dernière.
                        <br /><br />
                        Par dérogation à ce qui précède, BECOM'IN pourra faire usage du nom, de la dénomination, des marques et logos du Client en cours de contrat dans la mesure de ce qui est strictement nécessaire à l'exécution des Prestations, y compris dans des propositions de Prestations ultérieures.
                        <br />
                        <br /><br />

                        <b><u>Article 16 - Informatiques et libertés</u></b><br />
                        <br />
                        En application de l’article 40 de la loi n°78-17 du 6 janvier 1978, modifié par la loi n°2004-801 du 6 août 2004, il est rappelé que les données nominatives qui sont demandées au Client sont nécessaires au traitement de sa commande et sont destinées à un usage interne par BECOM'IN. Le Client dispose donc d’un droit d’accès, de rectification ou d’opposition s’agissant des informations le concernant, dans les délais prévus par la loi et la réglementation en vigueur.
                        <br />
                        <br /><br />
                        <b><u>Article 17 - Résiliation du Contrat</u></b><br />
                        <br />
                        Toute résiliation de contrat, et notamment après les 14 jours de droit à rétractation pour le Client particulier, devra être notifiée par le Client et acceptée explicitement par BECOM'IN.
                        <br />
                        Pour toute résiliation du contrat par le Client durant l'exécution des Prestations :<br />
                            <li>si un acompte a été versé, BECOM'IN le conservera.</li>
                            <li>si le règlement a été effectué d'avance, BECOM'IN remboursera le Client dans la limite des Prestations non encore effectuées, avec une décote de 20 %.</li>
                            <br />
                        Tout déplacement concernant le contrat et non annulé 24 heures avant la date prévue sera facturé selon le tarif en vigueur.
                        <br />
                        Une clause considérée comme abusive n'entraînera pas l'annulation des présentes conditions générales de vente, seule la clause concernée deviendrait caduque.
                        <br />
                        <br /><br />
                        <b><u>Article 18 - Indépendance</u></b><br />
                        <br />
                        L'exécution des Prestations n'entraîne en aucun cas la création d'une relation de mandat ou de société de fait, entre le Client et BECOM'IN. Aucune des parties n'est habilitée à engager ou lier l'autre.
                        <br />
                        <br /><br />

                        <b><u>Article 19 - Juridiction</u></b><br />
                        <br />
                        Les présentes Conditions Générales de Vente et les relations contractuelles y afférentes sont régies par le droit français. A défaut de résolution amiable, tout litige directement ou indirectement relatif aux relations contractuelles de la société BECOM'IN avec le Client relève de la compétence exclusive du Tribunal de Commerce de Caen (14).
                        <br /><br />
                    </Rules>
                </MainSection>
                <Test></Test>
            </PageWrapper>
        </HelmetProvider>
    )
}

export default CGV